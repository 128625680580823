
import {ComputedDatum, BarDatum, ResponsiveBar} from '@nivo/bar';
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    DailyAverage,
    getPropertyById,
    GetPropertyTrafficResponseType,
    getTrafficByPropertyId,
    HourlyAverage,
    MonthlyAverage,
    PropertiesDataType,
    PropertyResponse
} from "../../adapters/properties";
import {UNAUTHORIZED} from "../../consts";
import {
    ContentContainer,
    RowWrapper,
    DetailsWrapper,
    TabContainer,
    TabsWrapper,
    Tab,
    Title,
    RightSection,
    DotContainer,
    DotLabel,
    Dot,
    Dropdown,
    Option,
    HeaderContainer,
    HalfGraphContainer,
    SectionHeader,
    GraphContainer,
    GraphWrapper,
    TopRow,
    OuterContainer

} from "./styles";

type MonthlyData = {
    category: string;
    video_id: string;
    year: string;
    month: string;
    average: string;
};



const dayOrder = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const getBarColor = (bar: ComputedDatum<BarDatum>) => {
    const time = String(bar.indexValue);
    const hour = parseInt(time.split(":")[0], 10);

    if (hour >= 6 && hour < 9) return "#0043CE";
    if (hour >= 9 && hour < 10) return "#98B2FF";
    if (hour >= 10 && hour < 19) return "#CBDFFF";
    if (hour >= 19 && hour < 21) return "#98B2FF";
    return "#0043CE";
};

const formatTime = (time: string) => {
    const hour = parseInt(time.slice(0, 2), 10);
    const minute = time.slice(2);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert to 12-hour format
    return `${formattedHour}:${minute} ${ampm}`;
};

const getMaxValue = <T extends { average: number }>(data?: T[]): number => {
    if (!data || data.length === 0) {
        return 0;
    }

    const max = Math.max(...data.map((item) => item.average));

    return Math.ceil(max / 10) * 10; // Rounds up to the nearest 10
};


const DetailsV2 = () => {
    const { property_uuid = '' } = useParams();
    const navigate = useNavigate();
    const [dayType, setDayType] = useState('weekday');
    const [timeframe, setTimeframe] = useState('30day');
    const [dailyAverages, setDailyAverages] = useState<DailyAverage[] | null>(null);
    const [hourlyAverages, setHourlyAverages] = useState<HourlyAverage[] | null>(null)
    const [monthlyAverages, setMonthlyAverages] = useState<MonthlyAverage[] | null>(null);
    const [property, setProperty] = useState<PropertiesDataType | null>(null);


    // Helper functions
    const handleDayTypeToggle = (tab: string) => {
        setDayType(tab);
    };
    const handleTimeframeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        setTimeframe(value);
        setDailyAverages([])
        setHourlyAverages([])

        const getTraffic = async () => {
            const {data, error}: GetPropertyTrafficResponseType = await getTrafficByPropertyId({uuid: property_uuid, timeframe: value});

            if (error) {
                if (error.message === UNAUTHORIZED) {
                    navigate('/login');
                }
                return;
            }

            if (data) {
                setDailyAverages(data.daily_averages)
                setHourlyAverages(data.hourly_averages)
                setMonthlyAverages(data.monthly_averages)
            }
        };

        getTraffic();
    }

    const transformHourlyData = (data: HourlyAverage[] | undefined): BarDatum[] => {
        if (!data || data.length === 0) {
            return [];
        }

        return data.map(({ time, average }) => ({
            formattedTime: `${time.substring(0, 2)}:${time.substring(2, 4)}`, // Converts "2330" to "23:30"
            average: average, // Ensure it's a number
        }));
    };

    // Sort and filter hourly data
    const filterHourlyData = hourlyAverages
        ?.sort((a, b) => {
            const timeA = parseInt(a.time, 10);
            const timeB = parseInt(b.time, 10);

            // Shift the sorting order to start from 6 AM (0600) and go to 2 AM (0259)
            const adjustedA = timeA < 600 ? timeA + 2400 : timeA; // Push times before 6 AM to the end
            const adjustedB = timeB < 600 ? timeB + 2400 : timeB;

            return adjustedA - adjustedB; // Sort in ascending order based on the adjusted time
        })
        .map((item) => ({
            ...item,
            formattedTime: formatTime(item.time),
            average: Math.round(item.average) // Convert average to whole number
        }));

    // Handle People Hourly
    const filterPeopleData = filterHourlyData?.filter((item) => item.period === 'Total' && item.category === 'people')
    const filterWeekdayPeopleData = filterHourlyData?.filter((item) => item.period === 'Weekday' && item.category === 'people')
    const filterWeekendPeopleData = filterHourlyData?.filter((item) => item.period === 'Weekend' && item.category === 'people')
    const maxPeopleValue = getMaxValue(filterPeopleData)
    const weekdayData = transformHourlyData(filterWeekdayPeopleData);
    const weekendData = transformHourlyData(filterWeekendPeopleData);
    const overviewTotalData = transformHourlyData(filterPeopleData);
    const dataMap: Record<string, BarDatum[]> = {
        weekday: weekdayData,
        weekend: weekendData,
        'overview-total': overviewTotalData
    };

    // Handle vehicle Hourly
    const filterVehicleData = filterHourlyData?.filter((item) => item.period === 'Total' && item.category === 'vehicle')
    const filterWeekdayVehicleData = filterHourlyData?.filter((item) => item.period === 'Weekday' && item.category === 'vehicle')
    const filterWeekendVehicleData = filterHourlyData?.filter((item) => item.period === 'Weekend' && item.category === 'vehicle')
    const vehicleWeekdayData = transformHourlyData(filterWeekdayVehicleData);
    const vehicleWeekendData = transformHourlyData(filterWeekendVehicleData);
    const vehicleTotalData = transformHourlyData(filterVehicleData);
    const vehicleDataMap: Record<string, BarDatum[]> = {
        weekday: vehicleWeekdayData,
        weekend: vehicleWeekendData,
        'overview-total': vehicleTotalData
    };
    const maxVehicleValue = getMaxValue(filterVehicleData)

    // Handle Daily data
    const dailyPeopleBarChartData = dailyAverages?.sort((a, b) => {
        return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
    }).filter((item) => item.category === 'people').map((item) => ({
        day: item.day,
        average: parseFloat(item.average),
    })) || [];
    const maxDailyValue = getMaxValue(dailyPeopleBarChartData)

    // Handle Monthly data
    const processMonthlyData = (data: MonthlyData[] | null): { month: string; average: number }[] => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
        ];

        // Ensure data is always an array to prevent errors
        const validData = Array.isArray(data) ? data : [];

        // Define the object with explicit type
        const monthlyAverages: { [key: string]: number[] } = {};

        // Process each entry in the dataset
        validData.forEach((entry) => {
            const monthIndex = entry?.month ? parseInt(entry.month, 10) - 1 : null;
            const averageValue = entry?.average ? parseFloat(entry.average) : 0;

            if (monthIndex !== null && !isNaN(monthIndex) && monthIndex >= 0 && monthIndex < 12) {
                const monthName = months[monthIndex];

                if (!monthlyAverages[monthName]) {
                    monthlyAverages[monthName] = [];
                }
                monthlyAverages[monthName].push(averageValue);
            }
        });

        // Compute the final average for each month and ensure missing months are included
        return months.map((month) => ({
            month,
            average: monthlyAverages[month]
                ? monthlyAverages[month].reduce((sum: number, val: number) => sum + val, 0) / monthlyAverages[month].length
                : 0 // Default to 0 if no data for the month
        }));
    };
    const peopleMonthlyData = Array.isArray(monthlyAverages)
        ? monthlyAverages.filter((item) => item.category === 'people')
        : [];
    const monthlyPeopleBarChartData = processMonthlyData(peopleMonthlyData)
    const monthlyMaxValue = getMaxValue(monthlyPeopleBarChartData);


    useEffect(() => {
        const getProperty = async () => {
            const {data, error}: PropertyResponse = await getPropertyById({uuid: property_uuid});
            if (error) {
                if (error.message === UNAUTHORIZED) {
                    navigate('/login');
                }
                return;
            }
            if (data) {
                setProperty(data)
            }
        }

        const getTraffic = async () => {
            const {data, error}: GetPropertyTrafficResponseType = await getTrafficByPropertyId({uuid: property_uuid, timeframe: timeframe});
            if (error) {
                if (error.message === UNAUTHORIZED) {
                    navigate('/login');
                }
                return;
            }

            if (data) {
                setDailyAverages(data.daily_averages)
                setHourlyAverages(data.hourly_averages)
                setMonthlyAverages(data.monthly_averages)
            }
        };

        getProperty();
        getTraffic();
    }, [navigate, '/']);

    return (
        <ContentContainer>
            <DetailsWrapper>
                <OuterContainer>
                    <TopRow>
                        <TabContainer>
                            <TabsWrapper>
                                {["weekday", "weekend", "overview-total"].map((tab) => (
                                    <Tab
                                        key={tab}
                                        active={dayType === tab}
                                        onClick={() => handleDayTypeToggle(tab)}
                                    >
                                        {tab === "weekday" ? "Weekdays" : tab === "weekend" ? "Weekend" : "Overview - total"}
                                    </Tab>
                                ))}
                            </TabsWrapper>
                        </TabContainer>
                        <HeaderContainer>
                            <Title>Average People Traffic</Title>

                            <RightSection>
                                <DotContainer>
                                    <Dot color="#1E3A8A" /> <DotLabel>Night</DotLabel>
                                </DotContainer>
                                <DotContainer>
                                    <Dot color="#2563EB" /> <DotLabel>Day</DotLabel>
                                </DotContainer>

                                <Dropdown value={timeframe} onChange={handleTimeframeChange}>
                                    <Option value="30day">Monthly</Option>
                                    <Option value="7day">Weekly</Option>
                                    <Option value="all">All Time</Option>
                                </Dropdown>
                            </RightSection>
                        </HeaderContainer>
                    </TopRow>
                    <GraphContainer>
                        <ResponsiveBar
                            data={dataMap[dayType] || overviewTotalData}
                            keys={["average"]}
                            indexBy="formattedTime"
                            margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
                            padding={0.3}
                            colors={(bar) => getBarColor(bar)}
                            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Time",
                                legendPosition: "middle",
                                legendOffset: 40,
                                format: (value) => (value.endsWith(":00") ? value : ""), // Show only hourly labels
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Average",
                                legendPosition: "middle",
                                legendOffset: -40,
                            }}
                            tooltip={({ id, value, indexValue }) => (
                                <div style={{ background: "white", padding: "10px", border: "1px solid #ccc" }}>
                                    <strong>{indexValue}</strong>: {value}
                                </div>
                            )}
                            minValue={0}
                            maxValue={maxPeopleValue}
                            labelSkipHeight={100}
                            labelSkipWidth={100}
                        />
                    </GraphContainer>
                </OuterContainer>
                <RowWrapper>
                    <HalfGraphContainer>
                        <SectionHeader>Daily People Totals</SectionHeader>
                        <GraphWrapper>
                            <ResponsiveBar
                                data={dailyPeopleBarChartData}
                                keys={["average"]}
                                indexBy="day"
                                margin={{ top: 0, right: 30, bottom: 100, left: 70 }}
                                padding={0.3}
                                colors="#8B5CFF"
                                minValue={0}
                                maxValue={maxDailyValue}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Day",
                                    legendPosition: "middle",
                                    legendOffset: 40,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Average",
                                    legendPosition: "middle",
                                    legendOffset: -50,
                                }}
                                tooltip={({ id, value }) => (
                                    <div
                                        style={{
                                            background: "white",
                                            padding: "6px 12px",
                                            borderRadius: "4px",
                                            boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
                                        }}
                                    >
                                        <strong>{id}</strong>: {value}
                                    </div>
                                )}
                                labelSkipHeight={100}
                                labelSkipWidth={100}
                            />
                        </GraphWrapper>
                    </HalfGraphContainer>
                    <HalfGraphContainer>
                        <SectionHeader>Monthly Totals</SectionHeader>
                        <GraphWrapper>
                            <ResponsiveBar
                                data={monthlyPeopleBarChartData} // Assuming a different dataset
                                keys={["average"]}
                                indexBy="month"
                                margin={{ top: 0, right: 30, bottom: 100, left: 70  }}
                                padding={0.3}
                                colors="#FF6B6B" // Different color for distinction
                                maxValue={monthlyMaxValue}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Day",
                                    legendPosition: "middle",
                                    legendOffset: 40,
                                }}
                                axisLeft={{
                                    tickSize: 10,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: "Average",
                                    legendPosition: "middle",
                                    legendOffset: -80
                                }}
                                tooltip={({ id, value }) => (
                                    <div
                                        style={{
                                            background: "white",
                                            padding: "6px 12px",
                                            borderRadius: "4px",
                                            boxShadow: "0 2px 4px rgba(0,0,0,0.15)",
                                        }}
                                    >
                                        <strong>{id}</strong>: {value}
                                    </div>
                                )}
                                labelSkipHeight={100}
                                labelSkipWidth={100}
                            />
                        </GraphWrapper>
                    </HalfGraphContainer>
                </RowWrapper>
                <OuterContainer>
                    <TopRow>
                        <TabContainer>
                            <TabsWrapper>
                                {["weekday", "weekend", "overview-total"].map((tab) => (
                                    <Tab
                                        key={tab}
                                        active={dayType === tab}
                                        onClick={() => handleDayTypeToggle(tab)}
                                    >
                                        {tab === "weekday" ? "Weekdays" : tab === "weekend" ? "Weekend" : "Overview - total"}
                                    </Tab>
                                ))}
                            </TabsWrapper>
                        </TabContainer>
                        <HeaderContainer>
                            <Title>Average Vehicle Traffic</Title>
                            <RightSection>
                                <DotContainer>
                                    <Dot color="#1E3A8A" /> <DotLabel>Night</DotLabel>
                                </DotContainer>
                                <DotContainer>
                                    <Dot color="#2563EB" /> <DotLabel>Day</DotLabel>
                                </DotContainer>
                                <Dropdown value={timeframe} onChange={handleTimeframeChange}>
                                    <Option value="30day">Monthly</Option>
                                    <Option value="7day">Weekly</Option>
                                    <Option value="all">All Time</Option>
                                </Dropdown>
                            </RightSection>
                        </HeaderContainer>
                    </TopRow>
                    <GraphContainer>
                        <ResponsiveBar
                            data={vehicleDataMap[dayType] || vehicleTotalData}
                            keys={["average"]}
                            indexBy="formattedTime"
                            margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
                            padding={0.3}
                            colors={(bar) => getBarColor(bar)}
                            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                            axisBottom={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Time",
                                legendPosition: "middle",
                                legendOffset: 40,
                                format: (value) => (value.endsWith(":00") ? value : ""), // Show only hourly labels
                            }}
                            axisLeft={{
                                tickSize: 5,
                                tickPadding: 5,
                                tickRotation: 0,
                                legend: "Average",
                                legendPosition: "middle",
                                legendOffset: -40,
                            }}
                            tooltip={({ id, value, indexValue }) => (
                                <div style={{ background: "white", padding: "10px", border: "1px solid #ccc" }}>
                                    <strong>{indexValue}</strong>: {value}
                                </div>
                            )}
                            minValue={0}
                            maxValue={maxVehicleValue}
                            labelSkipHeight={100}
                            labelSkipWidth={100}
                        />
                    </GraphContainer>
                </OuterContainer>
            </DetailsWrapper>
        </ContentContainer>
    );
};

export default DetailsV2;
