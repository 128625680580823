import React, { useState } from 'react';
import {ThemeProvider, BaseStyles, Heading } from '@primer/react';
import './App.css';
import Login from './containers/Authentication/Login';
import { Routes, Route } from "react-router-dom";
import Register from './containers/Authentication/Register';
import Dashboard from './containers/Dashboard';
import Details from './containers/Details';
import DetailsV2 from './containers/DetailsV2';
import { AuthenticationContext } from './context/Authentication';
import {JWTLoginResponseType, LoginResponseType} from './adapters/authentication';
import VideosList from "./containers/Videos/List"
import AuditImageList from "./containers/Videos/Read"
import { AppNavBar, AppRoutesWrapper, LogoNavBarWrapper, StyledLink, StyledLogo, UserInfoNavBarWrapper } from './styles';
import { isEmpty } from 'lodash';
import LabelAudit from './containers/LabelAudit';
import lightTheme from './assets/logo/lightTheme.svg'

function App() {
  const [authenticatedUser, setAuthenticatedUser] = useState<LoginResponseType |  null>(null);
  const [authenticationToken, setAuthenticationToken] = useState<JWTLoginResponseType |  null>(null);

  return (
    <ThemeProvider>
      <BaseStyles>
      <AuthenticationContext.Provider value={authenticatedUser}>
        <AppNavBar>
          <LogoNavBarWrapper>
            <StyledLogo src={lightTheme} />
          </LogoNavBarWrapper>
          <UserInfoNavBarWrapper>
            <Heading sx={{fontSize: 2, mb: 3}}><StyledLink to="/">Dashboard</StyledLink></Heading>
            <Heading sx={{fontSize: 2, mb: 3}}><StyledLink to="/audit">Audit</StyledLink></Heading>
            {!isEmpty(authenticatedUser) && authenticatedUser.username}
          </UserInfoNavBarWrapper>
        </AppNavBar>
        <AppRoutesWrapper className="App">
          <Routes>
            <Route path="/login" element={<Login authenticationToken={authenticationToken} setAuthenticationToken={setAuthenticationToken}/>} />
            <Route path="/register" element={<Register />} />
            {/* <Route index element={<NavigationHeader />}> */}
              <Route path="/" element={<Dashboard />} />
              {/*<Route path="/details/:property_uuid/" element={<Details />} />*/}
            <Route path="/details/:property_uuid/" element={<DetailsV2 />} />
              <Route path="/audit" element={<LabelAudit/>} />
              <Route path="/audit/videos" element={<VideosList/>} />
              <Route path="/audit/videos/:video_name/" element={<AuditImageList/>} />
              {/*<Route path="/tracking/:uuid/:type" element={<ObjectTrackingList/>} />*/}
              {/*<Route path="/tracking/:uuid/objects/:object_id" element={<VideoPlayer/>} />*/}
            {/* </Route> */}

          </Routes>
        </AppRoutesWrapper>
      </AuthenticationContext.Provider>
      </BaseStyles>
    </ThemeProvider>
  );
}

export default App;
