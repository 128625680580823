import { UNAUTHORIZED } from "../consts";
import { PROPERTIES_ROUTE } from "./consts";
import { axiosInstance } from "./instance";

type GetPropertyByIdRequestType = {
  uuid: string;
}

type GetPropertyDemographicsByIdRequestType = {
  uuid: string;
}

type GetPropertyTrafficByIdRequestType = {
  uuid: string;
  timeframe: string;
}

export type PropertyDataType = {
  average_daily_traffic: {
    weekday: number
    weekend: number
  },
  average_hourly_traffic: {
    weekday: number
    weekend: number
  },
  average_age: {
    male: number
    female: number
  },
  gender_percentage: {
    male: number
    female: number
  },
  age_distribution: {
    data: number[],
  },
  daily_traffic: { timestamp: string, count: number }[],
  fifteen_min_interval: { timestamp: string, count: number }[]
}

export type PropertyDemographic = {
  error: Error
  data: DemographicDataType
}

export type DemographicDataType = {
  average_age: {
    male: number
    female: number
  },
  gender_percentage: {
    male: number
    female: number
  },
  age_distribution: {
    data: number[],
  },
}

export type PropertyResponse = {
  error: Error
  data: PropertiesDataType
}

export type TrafficDataType = {
  average_daily_traffic: {
    weekday: number
    weekend: number
  },
  average_hourly_traffic: {
    weekday: number
    weekend: number
  },
  daily_traffic: { day: string, count: number }[],
  fifteen_min_interval: { timestamp: string, count: number }[]
}


export type PropertyType = {
  property_name: string;
  data: PropertyDataType;
  error: Error;
}

export type PropertiesDataType = {
  property_name: string;
  description: string;
  address: {
      id: number;
      street: string;
      city: string;
      state: string;
      zipcode: string;
      uuid: string;
  },
  uuid: string;
}

export type PropertiesType = {
  data: PropertiesDataType[],
  error: Error;
}

export type GetPropertiesResponseType = {
  data: PropertiesType
}

export type MonthlyAverage = {
  month: string; // "1"
  year: string; // "2025"
  category: string; // e.g., "people"
  video_id: string; // e.g., "01"
  average: string; // e.g., "8261.625"
};

export type DailyAverage = {
  day: string; // e.g., "Thursday"
  category: string; // e.g., "people"
  video_id: string; // e.g., "07"
  average: string; // e.g., "8261.625"
};

export type HourlyAverage = {
  period: string; // e.g., "Weekend"
  time: string; // e.g., "2330"
  category: string; // e.g., "people"
  average: number; // e.g., "80.66666666666667"
  hour: string; // e.g., "07"
};

export type PropertyTrafficDataType = {
  daily_averages: DailyAverage[]; // Array of daily averages
  hourly_averages: HourlyAverage[]; // Array of hourly averages
  monthly_averages: MonthlyAverage[] // Array of monthly averages
};

export type GetPropertyTrafficResponseType = {
  data: PropertyTrafficDataType,
  error: Error;
}

export async function getPropertyById({ uuid }: GetPropertyByIdRequestType): Promise<PropertyResponse | any> {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `${PROPERTIES_ROUTE}${uuid}/`,
    })
    return {
      data: response?.data,
      error: null};
  } catch (err: any) {

    if (err?.response?.status === 401) {
      return { property_name: '', data: {}, error: new Error(UNAUTHORIZED)}
    }

    return { property_name: '', data: {}, error: new Error(`Error getting property details from property ${uuid}: ${err}`)};
  }
};

export async function getDemographicsByPropertyId({ uuid }: GetPropertyDemographicsByIdRequestType): Promise<PropertyDemographic | any> {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `${PROPERTIES_ROUTE}${uuid}/demographics`,
    })

    return {
      data: response.data.data,
      error: null};
  } catch (err: any) {

    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED)}
    }

    return { data: {}, error: new Error(`Error getting property details from property ${uuid}: ${err}`)};
  }
};

export async function getTrafficByPropertyId({ uuid, timeframe }: GetPropertyTrafficByIdRequestType): Promise<GetPropertyTrafficResponseType | any> {
  try {
    const response = await axiosInstance({
      method: 'get',
      url: `${PROPERTIES_ROUTE}${uuid}/traffic?timeframe=${timeframe}`,
    })
    return {
      data: response.data,
      error: null};
  } catch (err: any) {

    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED)}
    }

    return { data: {}, error: new Error(`Error getting property details from property ${uuid}: ${err}`)};
  }
};

export async function getProperties(): Promise<PropertiesType | any> {

  try {
    const response: GetPropertiesResponseType = await axiosInstance({
      method: 'get',
      url: `${PROPERTIES_ROUTE}`,
    })

    return { data: response?.data, error: null };
  } catch (err: any) {

    if (err?.response?.status === 401) {
      return { data: {}, error: new Error(UNAUTHORIZED)}
    }

    return { data: {}, error: new Error(err)};
  }
};
