import styled from "styled-components";

interface TabProps {
    active: boolean;
}

export const HeaderText = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  margin: 24px 0;
  text-align: center;
  color: #333;
`;

export const RowWrapper = styled.div`
  display: flex; /* Align graphs side by side */
  justify-content: space-between; /* Space graphs evenly */
  align-items: flex-start; /* Align graphs at the top */
  width: 100%;
  height: 475px;
  gap: 16px; /* Adds spacing between the graphs */
  
  border: 1px solid rgba(0, 0, 0, 0.1); /* Thin, almost transparent border */
  border-radius: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  justify-content: center; /* Center the graph inside */
`;

export const GraphContainer = styled.div`
  width: 100%;
  height: 400px; /* Ensure graph has a fixed height */
`;


export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 80%;
  padding: 6px;
`;

export const SectionHeader = styled(HeaderText)`
  margin-top: 60px;
  width: 100%;
  font-size: 1.25em;
  font-weight: 600;
  color: #333;
  text-align: left;
`;

export const Divider = styled.hr`
  margin-top: 50px;
  border: none;
  border-top: 1px solid #ccc;
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
`;

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`;

export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Tab = styled.div<TabProps>`
  cursor: pointer;
  padding: 10px 15px;
  font-weight: bold;
  color: ${({ active }) => (active ? "#000" : "#777")};
  border-bottom: ${({ active }) => (active ? "solid #68D391" : "solid #E0E0E0")};
  transition: all 0.3s ease;

  &:hover {
    color: #000;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
`;

export const Title = styled.span`
  font-size: 1.125rem; /* text-lg */
  font-weight: 600; /* font-semibold */
  color: black;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Dot = styled.span<{ color: string }>`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;

export const DotLabel = styled.span`
  font-size: 0.875rem;
  color: black;
`;

export const Dropdown = styled.select`
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 6px 12px;
  color: black;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 6px rgba(59, 130, 246, 0.5);
  }
`;

export const Option = styled.option`
  font-size: 1rem;
`;

export const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; /* Ensures proper stacking */
  gap: 16px; /* Adds spacing between rows */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Thin, almost transparent border */
  border-radius: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  justify-content: center; /* Center the graph inside */
`;

export const HalfGraphContainer = styled.div`
  flex: 1; /* Ensures each graph takes 50% of the row */
  min-width: 0; /* Prevents flexbox overflow issues */
  height: 100%; /* Ensures a fixed height */
  border: 1px solid rgba(0, 0, 0, 0.1); /* Thin, almost transparent border */
  border-radius: 8px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the graph inside */
`;

export const GraphWrapper = styled.div`
  flex: 1; /* Ensures the graph does not exceed the container */
  height: 100%; /* Ensures proper height */
`;

export const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column; /* Ensures title and tabs stack properly */
`;
